@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');
@import './_mixins';

body, html {
  font-family: 'Tenor Sans', sans-serif;
  background-image: url("../assets/images/bg-image.png");
  background-size: contain;
  background-repeat: repeat-y;
}

// Navigation bar
.nav-bar{
  width: 100%;
  overflow: hidden;

  ul li {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    a{
      float: left;
      color: #FF00BF;
    }
  }
}

// Sections
section {
  margin: 50px auto 0; /* Use "auto" for horizontal centering */
  padding: 10px;
  width: 90%; 
  max-height: 100%; /* Ensure the grid-container does not exceed its parent's height */
  overflow: hidden;
}

.hero {
  margin-top: 1rem;
}

.section-header {
  display: flex;
  justify-content: center;
  align-items: center; 
  padding-bottom: 30px;
  position: relative;
}

.window-purple-bg {
  @include windowBgStyles($purple); /* Purple-600 background */
}

.window-purple-title {
  @include windowTitleStyles($purple); /* Purple-600 background */
}

.pink-button {
  @include buttonStyles($pink, 0.3rem); /* Example: Passing 'red' as the background color and 0.25rem as margin-right */
}

.yellow-button {
  @include buttonStyles($yellow, 0.3rem); /* Example: Passing 'yellow' as the background color and 0.25rem as margin-right */
}

.white-button {
  @include buttonStyles(white, 0); /* Example: Passing 'white' as the background color and 0px as margin-right */
}

.window-blue-bg {
  @include windowBgStyles($blue); /* Purple-600 background */
}

.window-blue-title {
  @include windowTitleStyles($blue); /* Purple-600 background */
}

.card-title{
  color: white; /* Equivalent to text-white */
  font-size: 1rem; /* Equivalent to text-3xl at 2xl breakpoint */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 769px){
    font-size: 1.1rem;
    margin-left: 0.4rem;
  }
}

.card-content {
  padding: 30px 5px 10px 5px;
  @media (min-width: 769px){
    padding: 30px 20px 10px;
  }

  p{
    font-size: 1rem;
    line-height: 1rem;
    white-space: pre-line;
    @media (min-width: 769px){
      font-size: 1.5rem;
    line-height: 1.5rem; 
    }
    
  }
}

.about-card {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: center;
  align-items: center;
}

.about-column {
  width: calc(50% - 20px); /* Subtract padding from the width */
  padding: 10px;
}

form {
  display: flex;
  flex-direction: column;
  text-align: center; /* Keep labels left-justified */
  width: 80%;
}

label {
  display: block;
  text-align: left; /* Keep labels left-justified */
}

input,
textarea {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  align-self: center; /* Center the button within the flex container */
  width: 100px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

/* Popup styles */
.popup {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  width: 300px;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  z-index: 9999;
}

.popup-content {
  color: white;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.button {
  display: inline-block;
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  margin-right: 10px;
}

.icon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

/* Styles for screens from 641px to 768px (medium screens) */
@media (max-width: 768px) {

  section{
    margin: 15px auto 0; /* Use "auto" for horizontal centering */
  }

  .section-header {
    padding-bottom: 10px;
  }

  .section-header img {
    height: 40px;
  }

  .about-column {
    width: 100%; /* Make columns full width on smaller screens */
  }
}



